<template>
  <div>
    <table class="table table-striped">
      <thead>
      <th>Redazione</th>
      <th>Eventi</th>
      <th>Destination</th>
      <th>Itinerari</th>
      <th>Download</th>
      <th>Media</th>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td>{{item.redazione}}</td>
        <td>
          <ul>
            <li v-for="(loc, indexLoc) in item.eventi" :key="indexLoc">
              {{indexLoc}} : <span v-for="(locDetail, indexLocDetail) in loc" :key="indexLocDetail">
                <span class="badge badge-light">{{ locDetail.lang}}</span>
                <span class="badge badge-success">{{ locDetail.data}}</span>
            </span>
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li v-for="(loc, indexLoc) in item.itinerari" :key="indexLoc">
              {{indexLoc}} : <span v-for="(locDetail, indexLocDetail) in loc" :key="indexLocDetail">
                <span class="badge badge-light">{{ locDetail.lang}}</span>
                <span class="badge badge-success">{{ locDetail.data}}</span>
            </span>
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li v-for="(loc, indexLoc) in item.itinerari" :key="indexLoc">
              {{indexLoc}} : <span v-for="(locDetail, indexLocDetail) in loc" :key="indexLocDetail">
                <span class="badge badge-light">{{ locDetail.lang}}</span>
                <span class="badge badge-success">{{ locDetail.data}}</span>
            </span>
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li v-for="(loc, indexLoc) in item.download" :key="indexLoc">
              {{indexLoc}} : <span v-for="(locDetail, indexLocDetail) in loc" :key="indexLocDetail">
                <span class="badge badge-light">{{ locDetail.lang}}</span>
                <span class="badge badge-success">{{ locDetail.data}}</span>
            </span>
            </li>
          </ul>
        </td>
        <td>
          <ul>
            n.d.
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'redazioni',
    props: {
      data: {},
    },
    data() {
      return {
        needSave: false,
      }
    }


  };
</script>
