<template>
    <div>
        <div class="card" style="height:auto;">
            <div class="card-image" v-if="$slots.image">
                <slot name="image"></slot>
            </div>
            <div class="card-header" v-if="$slots.header || title">
                <slot name="header">
                    <h3 class="card-title">{{title}}
                        <a class="collapser" data-toggle="collapse" :href="'#' + id" aria-expanded="false" :aria-controls="'#'+id">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                         </a>
                    </h3>
                    <p class="card-category" v-if="subTitle">{{subTitle}}</p>
                </slot>
            </div>
            <div :class="['card-body collapse', collapse ? '':  'show']" v-if="$slots.default" v-bind:id="id">
                <slot></slot>
            </div>
            <slot name="raw-content"></slot>
            <div class="card-footer" v-if="$slots.footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "card",
        props: {
            title: String,
            subTitle: String,
            collapse: {
                type: Boolean,
                default: false
            }

        },
        computed: {
            getId: function () {
                if (this.id == "")
                    return this.title;//this.title;
                return this.id;
            },

            getIdTargetCollapse: function () {
                return "#" + this.getIdTargetCollapse();
            }
        },

        created: function () {
            this.id = "id" + this._uid;
        },

        methods: {
            changeButton: function (x) {
                x.classList.toggle("change");
            }
        }
    };
</script>
