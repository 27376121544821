<template>
  <div>
    <div class="card" style="height:auto;">
      <div class="card-image" v-if="$slots.image">
        <slot name="image"></slot>
      </div>
      <div class="card-header px-1">
        <slot name="header">
          <h3 class="card-title row mx-0">

            <div class="col-auto d-flex align-items-center">
              <i class="fa fa-align-justify handle" style="font-size: 14px; cursor: move;"></i>
            </div>

            <input
              type="text"
              class="col card-header-input"
              :value="title"
              placeholder="Inserisci qui il titolo del contenuto aggiuntivo"
              v-on:input="$emit('input', $event.target.value)"
            />

            <div class="col-auto d-flex align-items-center">
              <a class="collapser" data-toggle="collapse" :href="'#' + id" aria-expanded="false" :aria-controls="'#'+id">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </a>
            </div>

          </h3>
        </slot>
      </div>
      <div :class="['card-body collapse', collapse ? '':  'show']" v-if="$slots.default" v-bind:id="id">
        <slot></slot>
      </div>
      <slot name="raw-content"></slot>
      <div class="card-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
input.card-header-input {
  border: 0px;
  padding: 4px;
}
input.card-header-input:focus {
  border: 1px dashed #999;
  padding: 3px;
}
</style>
<script>
export default {
  name: "addendum-card",

  props: {
    title: String,
    subTitle: String,
    collapse: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getId: function () {
      if (this.id == "")
        return this.title; // this.title;
      return this.id;
    },

    getIdTargetCollapse: function () {
      return "#" + this.getIdTargetCollapse();
    }
  },

  created: function () {
    this.id = "id" + this._uid;
  },

  methods: {
    changeButton: function (x) {
      x.classList.toggle("change");
    }
  }

};
</script>
