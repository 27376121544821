<template>
    <table class="table table-striped table-bordered">

          <tbody>
            <template v-for="evt in data">
              <template v-for="l in langs">
                <tr :class="l" :key="l + evt.id">
                  <td v-if="l == 'it'" rowspan="2">
                    {{ evt.revision ? evt.revision : evt.id }}
                  </td>
                  <td class="lang">{{ l }}</td>
                  <td>{{ evt.contents[l].title }}</td>
                  <td>
                    <statuses-span :contents="'' + evt.contents[l].status">{{
                      statuses[evt.contents[l].status]
                    }}</statuses-span>
                  </td>
                  <td v-if="l == 'it'" rowspan="2">
                    <router-link
                      :to="`events/${evt.id}`"
                      custom
                      v-slot="{ href }"
                    >
                      <a :href="href"
                      class="btn btn-primary w-100"
                        target="_blank"
                        role="link"
                      >
                        <span class="fa fa-pencil"></span>
                      </a>
                    </router-link>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
    </table>
</template>
<style lang="scss" scoped>
  table {
    tr.it {
      border-top: 3px solid #7e7e7e;
    }

    tr.en {
      border-bottom: 3px solid #7e7e7e;
    }

    td {
      padding: 5px !important;
    }


  }

  span.badge{
      padding: 10px !important;
    }
</style>

<script>
import StatusesSpan from "../altrama/StatusesSpan";
export default {
  components: {
    StatusesSpan,
  },
        name: 'eventi',
        props: {
            data: Array,
            langs: Array
        },
        data() {
          return {
statuses: [
        "Disattivo",
        "Attivo",
        "Bozza",
        "Da validare",
        "Da rivalidare",
      ],
          }
        }
    };
</script>
